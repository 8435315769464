import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import TimelineContext from "../context/TimelineContext";
import HeaderContext from "../context/HeaderContext";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";

const TimelineProvider = ({ children }) => {
  const { userInfo } = useContext(HeaderContext);
  const { getIdTokenClaims } = useAuth0();
  const [searchParams] = useSearchParams();
  const [task_id, setTask_id] = useState(null);
  const timeline_id = userInfo?.feature_flags?.timeline_maternity?.reference_id;
  const [dataSubscription, setDataSubscription] = useState(null);

  useEffect(() => {
    setTask_id(searchParams.get("task_id"));
  }, [searchParams]);

  const getDataTask = async () => {
    const id_token = await getIdTokenClaims();
    if (task_id) {
      return await API.get(
        "plennaAPI",
        `/api/v2/timelines/${timeline_id}/tasks/${task_id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${id_token.__raw}`,
          },
        }
      );
    }
  };

  const getDataTimeLine = async ({ timeline_id, quarter, page }) => {
    const id_token = await getIdTokenClaims();
    const resp = await API.get("plennaAPI", `/api/v2/timelines/`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      queryStringParameters: {
        timeline_id,
        quarter,
        page,
      },
    });
    return resp?.data;
  };

  const getUrlVideo = async (is_membership, id_membership, key_source) => {
    const id_token = await getIdTokenClaims();
    const resp = await API.get("plennaAPI", `/api/v2/assets`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      queryStringParameters: {
        is_membership,
        id_membership,
        key_source,
      },
    });
    return resp;
  };

  const getPatientsSubscriptions = async () => {
    const id_token = await getIdTokenClaims();
    if (id_token) {
      return await API.get("plennaAPI", `/api/v2/patients/subscriptions`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
      });
    }
  };

  const getMembershipDetails = async ({ is_desktop }) => {
    const id_token = await getIdTokenClaims();
    if (id_token) {
      return await API.get("plennaAPI", `/api/v2/memberships`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
        queryStringParameters: {
          is_desktop,
        },
      });
    }
  };

  const postSubscription = async () => {
    const id_token = await getIdTokenClaims();
    const resp = await API.post("plennaAPI", `/api/v2/subscriptions`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
        "Request-Channel": "app-web",
      },
      body: {
        priceStripeId:
          dataSubscription?.priceStripeId ||
          localStorage.getItem("priceStripeId"),
      },
    });
    return resp;
  };

  const getSetupIntent = async () => {
    const id_token = await getIdTokenClaims();
    const resp = await API.get("plennaAPI", "/api/v2/setup-intent", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      queryStringParameters: {
        price_stripe_id: dataSubscription.priceStripeId,
      },
    });
    return resp;
  };

  return (
    <TimelineContext.Provider
      value={{
        task_id,
        setTask_id,
        getDataTask,
        getDataTimeLine,
        getUrlVideo,
        getPatientsSubscriptions,
        getMembershipDetails,
        postSubscription,
        getSetupIntent,
        timeline_id,
        dataSubscription,
        setDataSubscription,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export default TimelineProvider;
