import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import Spinner from "../../components/UI/Spinner/SpinnerPlenna";
import LocationContext from "../../context/LocationContext";

const PrivateRoute = (props) => {
  const { currentLocation } = useContext(LocationContext);
  const [searchParams] = useSearchParams();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const pregnancy = searchParams.get("pregnancy");
  const utmParams = sessionStorage.getItem("utm");
  const webflowParam = sessionStorage.getItem("webflow");
  const allQueryParams = currentLocation?.search;

  //Save utm and webflow params
  useEffect(() => {
    if (allQueryParams.includes("utm") && utmParams === null) {
      const indexOfUTM = allQueryParams.indexOf("utm");
      const onlyUTMparams = allQueryParams.substring(indexOfUTM);
      sessionStorage.setItem("utm", onlyUTMparams);
    }

    if (allQueryParams.includes("webflow") && webflowParam === null) {
      const indexOfWebflow = allQueryParams.indexOf("webflow");
      const onlyWebflowParam = allQueryParams.substring(indexOfWebflow);
      sessionStorage.setItem("webflow", onlyWebflowParam);
    }
  }, [allQueryParams, utmParams, webflowParam]);

  //Handles route to redirect
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const routeToRedirect = currentLocation;
      if (routeToRedirect) {
        localStorage.setItem(
          "routeToRedirect",
          JSON.stringify(routeToRedirect)
        );
      }
    }
  }, [isAuthenticated, isLoading, currentLocation]);

  //Handles Pregnancy flowExeption
  useEffect(() => {
    if (pregnancy) {
      localStorage.setItem("pregnancy", JSON.stringify(pregnancy));
    }
  }, [pregnancy, isAuthenticated]);

  if (isLoading) {
    return (
      <Backdrop show={true}>
        <Spinner detailSpinner={false} />
      </Backdrop>
    );
  }

  if (!isAuthenticated && pregnancy) {
    const routeToRedirect = currentLocation;
    if (routeToRedirect) {
      localStorage.setItem("routeToRedirect", JSON.stringify(routeToRedirect));
    }

    loginWithRedirect({
      screen_hint: "signup",
      appState: {
        returnTo: "/auth",
      },
    });
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        replace
      />
    );
  }

  if (isAuthenticated) {
    return props.children ? props.children : <Outlet />;
  }
};

export default PrivateRoute;
