import React, { useState, useCallback } from "react";
import { API } from "aws-amplify";

import AppointmentsContext from "../context/AppointmentsContext";

const AppointmentsProvider = ({ children }) => {
  const [currentView, setCurrentView] = useState("Summary");

  const getAppointments = async (tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get("plennaAPI", "/booking/getAppointments", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  };
  const cancelAppointment = useCallback(async (appointmentId, tokenAuth) => {
    try {
      const resp = await API.patch(
        "plennaAPI",
        `/api/v2/booking/appointments/${appointmentId}/cancellation`,
        {
          headers: {
            Authorization: `Bearer ${tokenAuth.__raw}`,
            "Request-Channel": "app-web",
          },
        }
      );
      return resp.data;
    } catch (error) {
      throw new Error("Network Error");
    }
  }, []);
  const getAppointmentInfo = useCallback(async (appointment, tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get(
          "plennaAPI",
          `/api/v2/appointments/${appointment}/checkup`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${tokenAuth.__raw}`,
            },
          }
        );
        return resp.data;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const getResultsInfo = async (appointmentId, laboratoryId, tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get(
          "plennaAPI",
          `/health/getLaboratoryResults?appointment_id=${appointmentId}&laboratory_id=${laboratoryId}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${tokenAuth.__raw}`,
            },
          }
        );
        return resp.body;
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <AppointmentsContext.Provider
      value={{
        getAppointments,
        currentView,
        setCurrentView,
        getAppointmentInfo,
        getResultsInfo,
        cancelAppointment,
      }}
    >
      {children}
    </AppointmentsContext.Provider>
  );
};

export default AppointmentsProvider;
