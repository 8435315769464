import React from "react";
import classes from "./Error.module.css";
import { Grid } from "@mui/material";
import ContentAnimation from "../../../components/UI/ContentAnimation/ContentAnimation";
import { useNavigate } from "react-router-dom";
import GoTo from "../../../components/UI/GoTo/GoTo";
import Forward from "../../../components/UI/GoTo/Forward";
import saveError from "../../../assets/icons/Error_icon/saveError.png";
import connectionError from "../../../assets/icons/Error_icon/connectionError.png";
import miscalculationError from "../../../assets/icons/Error_icon/miscalculationError.png";

const Error = ({ errorType }) => {
  const errorTypes = {
    SaveError: {
      image: saveError,
      title: "Oops, ocurrió un error",
      description:
        "No hemos podido guardar tus respuestas. Por favor, vuelve a intentarlo.",
      tryAgainButton: "Intentar Nuevamente",
      stepOutButton: "Salir",
      stepOutButtonOnClick: () => {
        navigate("/home");
      },
    },
    ConnectionError: {
      image: connectionError,
      title: "Oops, ha habido un error de conexión",
      description: "Por favor, verifica tu conexión a internet.",
      tryAgainButton: "Intentar Nuevamente",
      stepOutButton: "Salir",
      stepOutButtonOnClick: () => {
        navigate("/home");
      },
    },
    MiscalculationError: {
      image: miscalculationError,
      title: "Oops, ocurrió un error en el cálculo",
      description: "Por favor, vuelve a intentarlo",
      tryAgainButton: "Intentar Nuevamente",
      stepOutButton: "Atención Plenna",
      stepOutButtonOnClick: () => {
        window.open(`https://wa.me/525543658774`);
      },
    },
  };

  const navigate = useNavigate();

  return (
    <ContentAnimation direction={"up"}>
      <div className={classes.background}>
        <img
          src={errorTypes[errorType].image}
          className={classes.icon}
          alt=""
        />
        <Grid container className={classes.wrap}>
          <Grid item xs={12} className={classes.titleContainer}>
            <div className={classes.title}>
              <span>{errorTypes[errorType].title}</span>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.descriptionContainer}>
            <span className={classes.description}>
              {errorTypes[errorType].description}
            </span>
          </Grid>
        </Grid>
        <Grid className={classes.buttonsContainer}>
          <Grid xs={12} md={12} lg={12}>
            <GoTo
              id={"btnTryAgain"}
              onClick={() => {
                navigate(0);
              }}
              typeStyle="forward"
              className="forward"
            >
              <Forward title={errorTypes[errorType].tryAgainButton} hideArrow />
            </GoTo>
          </Grid>
        </Grid>
        <Grid className={classes.buttonsContainer}>
          <Grid xs={12} md={12} lg={12}>
            <GoTo
              id={"btnOut"}
              onClick={errorTypes[errorType].stepOutButtonOnClick}
              typeStyle="outlined"
              className="outlined"
            >
              <Forward title={errorTypes[errorType].stepOutButton} hideArrow />
            </GoTo>
          </Grid>
        </Grid>
      </div>
    </ContentAnimation>
  );
};

export default Error;
