import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useAuth0 } from "@auth0/auth0-react";

import Layout from "../components/Layout/Layout";
import Routes from "../Routes/Routes";
import HeaderProvider from "../providers/HeaderProvider";
import LocationProvider from "../providers/LocationProvider";

import "./pregnancy.css";
import "./default.css";

function App() {
  const { isAuthenticated, logout, isLoading } = useAuth0();
  const [mode, setMode] = useState("default");

  if (window.location.pathname === "/") {
    window.location.pathname = "/login";
  }

  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
    },
    palette: {
      primary: {
        main: mode === "pregnancy" ? "#573377" : "#7b1646",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HeaderProvider setMode={setMode}>
          <LocationProvider>
            <Layout>
              <Routes
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                logout={logout}
              />
            </Layout>
          </LocationProvider>
        </HeaderProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
