import React, { useState } from "react";
import { API } from "aws-amplify";

import classes from "./Signup.module.css";

import Button from "../../components/UI/Button/Button";
import Input from '../../components/UI/Input/Input'

const Signup = () => {

  const [user, setUser] = useState({})

  const confirmPassword = () => {
    if (!user.password || !user.password){
      return 'nopass'
    } else if (user.password === user.cpassword) {
      return 'match'
    } else{
      return 'not match'
    }
  }

  const signUp = async () => {

    const response = await API.post("plennaAPI", "/auth/signUp", {
      headers: {
        "Content-Type": "application/json"
      },
      body: {
        "email": user.email,
        "password": user.password,
        "username": user.email.split('@')[0]
    }
    });
    console.log(response)
  }

  return (
    <div className={classes.Container} >
      <Input
        type=''
        value={user.email}
        onChange={e => setUser({ ...user, email: e.target.value })}
        className=''
        label='email'
        autoComplete=''
        maxLength=''
        required=''
      />
      <Input
        type='password'
        value={user.password}
        onChange={e => setUser({ ...user, password: e.target.value })}
        className=''
        label='passowrd'
        autoComplete=''
        maxLength=''
        required=''
      />
      <Input
        type='password'
        value={user.cpassword}
        onChange={e => setUser({ ...user, cpassword: e.target.value })}
        className=''
        label='confirm password'
        autoComplete=''
        maxLength=''
        required=''
      />
      <div>
        <Button disabled={confirmPassword() !== 'match'} onClick={() => signUp()} >Sign Up</Button>
      </div>
      <div style={{ color: 'red' }}>
        {confirmPassword()}
      </div>
    </div>

  )
};

export default Signup;
