import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import ParametersContext from "../context/ParametersContext";

const ParametersProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const [tokenAuth, setTokenAuth] = useState("");
  const [data, setData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [formResults, setFormResults] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [enabledButton, setEnabledButton] = useState(true);
  const [verifiedDueDate, setVerifiedDueDate] = useState(false);
  const [verifiedCalculatedData, setVerifiedCalculatedData] = useState(false);

  const tokenAuth0 = getIdTokenClaims();
  const navigate = useNavigate();

  useEffect(() => {
    tokenAuth0
      .then((resp) => {
        if (resp !== undefined) {
          setTokenAuth(resp);
        }
      })
      .catch((error) => console.log(error));
  }, [tokenAuth0]);

  useEffect(() => {
    setShowLoader(true);
    if (tokenAuth !== "") {
      return API.get("plennaAPI", `/health/pregnancyInfo`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${tokenAuth.__raw}`,
        },
      })
        .then((resp) => {
          setData(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenAuth]);

  const saveDataParameters = async () => {
    setShowLoader(true);
    const {
      dueDate,
      estimatedBirthDate,
      gestationalDay,
      gestationalWeek,
      lastMenstruationDate,
    } = formResults;

    const body = {
      gestational_week:
        gestationalWeek.length > 2
          ? gestationalWeek.split(" ")[0]
          : gestationalWeek,
      gestational_day:
        gestationalDay.length > 2
          ? gestationalDay.split(" ")[0]
          : gestationalDay,
      estimated_birth_date: format(new Date(estimatedBirthDate), "MM/dd/YYY"),
      last_menstruation: format(new Date(lastMenstruationDate), "MM/dd/YYY"),
      due_date: dueDate ? format(new Date(dueDate), "MM/dd/YYY") : "",
    };

    if (dueDate === -1) {
      delete body.due_date;
    }
    return await API.patch("plennaAPI", `/health/pregnancyInfo`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${tokenAuth.__raw}`,
      },
      body,
    })
      .then((resp) => {
        if (resp.success) {
          setShowLoader(false);
          setTypeMessage("success");
          setShowMessage(true);
          setIsDisabled(true);
          setEnabledButton(true);
          setTimeout(() => {
            setShowMessage(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dontSaveDataParameters = () => {
    setShowMessage(false);
    setIsDisabled(true);
    setEnabledButton(true);
  };

  const updateGrief = async () => {
    return await API.post("plennaAPI", "/auth/grief", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${tokenAuth.__raw}`,
      },
      body: {
        grief: true,
      },
    })
      .then(() => {
        navigate("/perdida");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ParametersContext.Provider
      value={{
        data,
        showLoader,
        setShowLoader,
        formResults,
        setFormResults,
        saveDataParameters,
        dontSaveDataParameters,
        isDisabled,
        setIsDisabled,
        enabledButton,
        setEnabledButton,
        showMessage,
        setShowMessage,
        typeMessage,
        setTypeMessage,
        verifiedDueDate,
        setVerifiedDueDate,
        verifiedCalculatedData,
        setVerifiedCalculatedData,
        updateGrief,
      }}
    >
      {children}
    </ParametersContext.Provider>
  );
};

export default ParametersProvider;
