import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { API } from "aws-amplify";

import PsychologicalAssessmentContext from "../context/PsychologicalAssessmentContext";

const queryService = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);

  return parseInt(params.get("serviceId")) || 1;
};

const PsychologicalAssessmentProvider = ({ children }) => {
  const [currentView, setCurrentView] = useState(undefined);
  const [serviceId, setServiceId] = useState(queryService);
  const [countStepper, setCountStepper] = useState(0);
  const [tokenAuth, setTokenAuth] = useState("");

  const { getIdTokenClaims } = useAuth0();

  const tokenAuth0 = getIdTokenClaims();

  const getPsychologist = async (token) => {
    try {
      const resp = await API.get("plennaAPI", "/booking/getPsychologist", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token.__raw}`,
        },
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const assignPsychologist = async (token, body) => {
    try {
      const resp = await API.post("plennaAPI", "/booking/assignPsychologist", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token.__raw}`,
        },
        body,
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    tokenAuth0
      .then((resp) => {
        if (resp !== undefined) {
          setTokenAuth(resp);
        }
      })
      .catch((error) => console.log(error));
  }, [tokenAuth0]);

  useEffect(() => {
    localStorage.removeItem("doctors");
  }, []);

  return (
    <PsychologicalAssessmentContext.Provider
      value={{
        serviceId,
        setServiceId,
        currentView,
        setCurrentView,
        countStepper,
        setCountStepper,
        getPsychologist,
        assignPsychologist,
        tokenAuth,
      }}
    >
      {children}
    </PsychologicalAssessmentContext.Provider>
  );
};

export default PsychologicalAssessmentProvider;
