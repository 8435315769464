import React, {useState, useRef} from 'react';
import Slide from '@mui/material/Slide';

const SlideAnimation = ({props}) => {
  setTimeout(() => setChecked(true),1300);
  const [checked, setChecked] = useState(false);
  const containerRef = useRef(null);

  const {
    children,
    direction
    } = props
  return (
    <Slide direction={direction}
      in={checked}
      container={containerRef.current}
      style={{ transformOrigin: '0 0 0' }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      {children}
    </Slide>
  );
}

export default SlideAnimation;
