import React, { useState, useEffect, useContext, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWRImmutable from "swr/immutable";
import { API } from "aws-amplify";

import SpecialitiesContext from "../context/SpecialitiesContext";
import HeaderContext from "../context/HeaderContext";

const SpecialitiesProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const [currentView, setCurrentView] = useState("");
  const [dataSpecialtiesInfo, setDataSpecialtiesInfo] = useState(null);
  const [serviceDetail, setServiceDetail] = useState(null);
  const [specialty_id, setSpecialty_id] = useState(null);
  const { userInfo } = useContext(HeaderContext);

  const { data, isLoading } = useSWRImmutable(
    `getSpecialtiesInfo_${userInfo.mode}`,
    (args) => getSpecialtiesInfo(args)
  );

  const getSpecialtiesInfo = async () => {
    const id_token = await getIdTokenClaims();
    try {
      const resp = await API.get("plennaAPI", "/api/v2/specialties", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const getSpecialtyServices = useCallback(async () => {
    const id_token = await getIdTokenClaims();
    try {
      const resp = await API.get(
        "plennaAPI",
        `/api/v2/specialties/${specialty_id}/services`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${id_token.__raw}`,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log(error);
    }
  }, [getIdTokenClaims, specialty_id]);

  const getServiceDetail = useCallback(
    async (serviceId) => {
      const id_token = await getIdTokenClaims();
      try {
        const resp = await API.get(
          "plennaAPI",
          `/api/v2/services?plenna_id=${serviceId}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${id_token.__raw}`,
            },
          }
        );
        setServiceDetail(resp);
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    [getIdTokenClaims]
  );

  useEffect(() => {
    if (!isLoading && data) {
      setDataSpecialtiesInfo(data.data.specialties);
    }
  }, [data, isLoading]);

  return (
    <SpecialitiesContext.Provider
      value={{
        data,
        isLoading,
        dataSpecialtiesInfo,
        getServiceDetail,
        serviceDetail,
        currentView,
        setCurrentView,
        specialty_id,
        setSpecialty_id,
        getSpecialtyServices,
      }}
    >
      {children}
    </SpecialitiesContext.Provider>
  );
};

export default SpecialitiesProvider;
