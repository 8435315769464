import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  LogoPlennaPregnancy,
  SupportIcon,
} from "../../components/UI/Icons/Icons";
import HeaderLogo from "../../assets/images/Logotipo horizontal/Logotiopo horizontal.png";
import Menu from "./Menu";

import HeaderContext from "../../context/HeaderContext";
import classes from "./Header.module.css";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const HeaderSolid = () => {
  const { variant, userInfo, title } = useContext(HeaderContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Menu
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <header className={`${classes.Header} ${classes.solid}`}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ padding: 2, margin: 0 }}
          onClick={toggleDrawer}
          className={classes.menuIcon}
        >
          <MenuIcon />
        </IconButton>
        {!!title ? (
          <h2 className={classes.title}>{title}</h2>
        ) : (
          <div style={{ width: "100%" }}>
            {userInfo.mode === "pregnancy" ? (
              <div className={`${classes.Logo} ${classes.LogoSolid}`}>
                <LogoPlennaPregnancy />
              </div>
            ) : (
              <img
                src={HeaderLogo}
                alt="Plenna Name Logo"
                className={`${classes.Logo} ${classes.LogoSolid}`}
              />
            )}
          </div>
        )}

        {userInfo?.subscription?.is_active && (
          <img
            src={userInfo.subscription.icon}
            alt={userInfo.subscription.name}
            className={classes.icon}
            style={{ padding: "16px 0px" }}
            onClick={() => navigate("/plan-actual")}
          />
        )}
        {!variant ? (
          <img
            src={userInfo && userInfo.photo}
            alt="User"
            className={classes.icon}
            onClick={() => navigate("/perfil?medium=header")}
          />
        ) : (
          <a
            id="btnContact"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wa.me/525543658774?text=¡Hola,%20soy%20${
              userInfo && userInfo.name
            }%20Tengo%20una%20duda...`}
          >
            <SupportIcon className={classes.icon} />
          </a>
        )}
      </header>
    </>
  );
};

export default HeaderSolid;
