export const objectivesDictionary = {
  "Cuidar mi salud": "health",
  "Quedar embarazada": "preconception",
  "Seguir mi embarazo": "pregnancy",
};

export const articlesCategoryDictionary = {
  Psicología: "psychology",
  Obstetricia: "obstetrics",
  Nutrición: "nutrition",
  General: "general",
};

export const specialtiesDictionary = {
  Obstetricia: "pregnancy control",
  "Psicología Perinatal": "perinatal psychology",
  "Nutrición Perinatal": "perinatal nutrition",
  "Laboratorios y pruebas": "labs tests",
  Vacunas: "vaccines",
  "Salud ginecológica": "gynecology",
  "Psicología y balance": "psychology",
  Nutrición: "nutrition",
  "Control del embarazo": "obstetrics",
};

export const modalityDictionary = {
  Presencial: "in person",
  Virtual: "virtual",
};

export const monthsCap = {
  enero: "Enero",
  febrero: "Febrero",
  marzo: "Marzo",
  abril: "Abril",
  mayo: "Mayo",
  junio: "Junio",
  julio: "Julio",
  agosto: "Agosto",
  septiembre: "Septiembre",
  octubre: "Octubre",
  noviembre: "Noviembre",
  diciembre: "Diciembre",
};
export const daysCap = {
  lunes: "Lunes",
  martes: "Martes",
  miércoles: "Miércoles",
  jueves: "Jueves",
  viernes: "Viernes",
  sábado: "Sábado",
  domingo: "Domingo",
};
