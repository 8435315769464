import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";

import classes from "./Header.module.css";
import {
  CloseIcon,
  PlennaHeartIcon,
  StethoscopeIcon,
  ProfileIcon,
  MyResultsIcon,
  CalendarIcon,
  MyGuideIcon,
  SyntomsIcon,
  WhatsAppIcon,
} from "../../components/UI/Icons/Icons";
import HeaderLogo from "../../assets/images/Logotipo horizontal/Logotiopo horizontal.png";
import { useViewport } from "../../hooks/UseViewport/UseViewport";
import LocationContext from "../../context/LocationContext";
import HeaderContext from "../../context/HeaderContext";
import GoTo from "../../components/UI/GoTo/GoTo";
import Forward from "../../components/UI/GoTo/Forward";
import analytics from "../../analytics";

const Menu = ({ open, toggleDrawer }) => {
  const [selectButton, setSelectButton] = useState(undefined);
  const { width } = useViewport();
  const { currentLocation } = useContext(LocationContext);
  const { userInfo } = useContext(HeaderContext);
  const navigate = useNavigate();
  const timelineMaternityActive =
    userInfo?.feature_flags?.timeline_maternity?.is_active;
  const objectiveFollowMyPregnancy =
    userInfo?.objective === "Seguir mi embarazo";

  const menuOptions = [
    {
      title: "Inicio",
      icon: <PlennaHeartIcon />,
      redirect: "/home",
      id: "btnMenuHome",
    },
    {
      title: "Mis citas",
      icon: <StethoscopeIcon />,
      redirect: "/citas?view=citas&medium=menu",
      id: "btnMenuAppointments",
    },
    {
      title: "Mis Resultados",
      icon: <MyResultsIcon />,
      redirect: "/mis-resultados?medium=menu",
      id: "btnMyResults",
    },
    {
      title: "Mi guía",
      icon: <MyGuideIcon />,
      redirect: "/mi-guia?medium=menu",
      id: "btnMyGuide",
    },
    {
      title: "Síntomas",
      icon: <SyntomsIcon />,
      redirect: `/maternidad/sintomas-comunes/${
        userInfo?.quarter ? userInfo?.quarter : 1
      }`,
      id: "btnMySyntoms",
    },
    {
      title: "Perfil",
      icon: <ProfileIcon />,
      redirect: "/perfil?medium=menu",
      id: "btnMenuProfile",
    },
  ];

  const scheduleButton = {
    title: "Agendar",
    icon: <CalendarIcon />,
    redirect: "/especialidades",
    id: "btnMenuSpecialties",
  };

  const textToRender = () => {
    let text;
    if (userInfo?.subscription?.is_active) {
      text = (
        <p className={classes.textCounseling}>
          Consejería obstétrica incluida en tu
          <span style={{ fontWeight: 700 }}>
            {" "}
            {userInfo?.subscription.name}
          </span>
        </p>
      );
    } else {
      text = (
        <p className={classes.textCounseling}>
          Consejería obstétrica incluida en nuestra
          <span style={{ fontWeight: 700 }}> Membresía de embarazo</span>
        </p>
      );
    }
    return text;
  };

  useEffect(() => {
    menuOptions.forEach((option) => {
      if (option?.redirect?.includes(currentLocation?.pathname)) {
        setSelectButton(option.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation]);

  return (
    <>
      <Drawer
        sx={{
          width: 210,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 210,
            overflowY: "inherit",
          },
        }}
        variant={width > 1200 ? "permanent" : "temporary"}
        anchor={"left"}
        open={open}
        onClose={toggleDrawer}
      >
        <div className={classes.menu}>
          <div
            onClick={toggleDrawer}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </div>
          <div className={classes.containerButtons}>
            {menuOptions.map((option) => {
              return (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  id={option.id}
                  className={`${classes.buttonMenu} ${
                    selectButton === option.id && classes.buttonMenuActive
                  } ${
                    (option.id === "btnMyResults" ||
                      option.id === "btnMySyntoms") &&
                    classes.buttonMenuResults
                  }`}
                  key={option.id}
                  onClick={() => {
                    toggleDrawer();
                    navigate(option.redirect);
                  }}
                  style={{
                    display: `${
                      (!timelineMaternityActive ||
                        !objectiveFollowMyPregnancy) &&
                      (option?.id === "btnMyGuide" ||
                        option?.id === "btnMySyntoms") &&
                      "none"
                    }`,
                  }}
                >
                  <Grid
                    item
                    display={"flex"}
                    xs={2}
                  >
                    {option.icon}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                  >
                    <p>{option.title}</p>
                  </Grid>
                </Grid>
              );
            })}

            <GoTo
              typeStyle={"forward"}
              onClick={() => {
                toggleDrawer();
                navigate(scheduleButton.redirect);
                analytics.track("Schedule Flow Started");
              }}
              className={classes.scheduleButton}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                id={scheduleButton.id}
                key={scheduleButton.id}
              >
                <Grid
                  item
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                  xs={3}
                >
                  {scheduleButton.icon}
                </Grid>
                <Grid
                  item
                  xs={9}
                >
                  <Forward
                    xs={10}
                    md={10}
                    lg={10}
                    hideArrow
                    title={scheduleButton.title}
                    className={classes.customForward}
                  />
                </Grid>
              </Grid>
            </GoTo>
          </div>
          {objectiveFollowMyPregnancy && (
            <div className={classes.counselingContainer}>
              <hr className={classes.divider} />
              <img
                src={userInfo?.subscription?.icon_side}
                alt={"counseling"}
              />
              {textToRender()}
              <GoTo
                typeStyle="outlined"
                className={classes.goToCounseling}
                onClick={() => {
                  toggleDrawer();
                  navigate(
                    userInfo?.subscription?.is_active
                      ? "/contacto-consejeria"
                      : `/detalles-membresia?isActive=false&medium=menu&nameMembership=undefined&hadTrialDays=${userInfo?.subscription?.had_trial_days}`
                  );
                }}
              >
                <Forward
                  icon={userInfo?.subscription?.is_active && <WhatsAppIcon />}
                  hideArrow
                  title={
                    userInfo?.subscription?.is_active
                      ? "Enviar mensaje"
                      : "Saber más"
                  }
                />
              </GoTo>
            </div>
          )}
          {!objectiveFollowMyPregnancy && (
            <div className={classes.logoMenu}>
              <img
                src={HeaderLogo}
                alt="Plenna Name Logo"
                className={`${classes.Logo} ${classes.footerLogo}`}
              />
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Menu;
