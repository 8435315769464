const serviceIsAPackage = (idService) => {
  // servicios con 2, 4 y 7 sesiones

  const services = [7, 8, 83, 84, 88, 89, 52, 80, 81, 90];

  if (services.includes(idService)) {
    return true;
  }
};

export default serviceIsAPackage;
