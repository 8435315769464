import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import "./input.css";
const input = ({
  label,
  type,
  value,
  onChange,
  name,
  autocomplete,
  maxlength,
  required,
  containerClass,
  className,
  labelClass,
}) => {
  return (
    <Fragment>
      <Grid container className={containerClass}>
        <Grid item xs={6} sm={5}>
          <label className={labelClass}>{label}</label>
        </Grid>
        <Grid item xs={4}>
          <input
            type={type}
            value={value}
            onChange={onChange}
            className={className}
            name={name}
            autoComplete={autocomplete}
            maxLength={maxlength}
            required={required}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default input;
