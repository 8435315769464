import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import { Auth0Provider } from "@auth0/auth0-react";

import Amplify from "aws-amplify";
import config from "./aws-exports";
Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN} // <- env variable
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID} // <- env variable
      redirectUri={`${window.location.origin}/auth`}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
