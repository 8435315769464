import React, { useContext, useState, useEffect } from "react";
import HeaderContext from "../../context/HeaderContext";
import LocationContext from "../../context/LocationContext";
import classes from "./Header.module.css";
import Menu from "./Menu";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { SupportIcon } from "../../components/UI/Icons/Icons";
import { useNavigate } from "react-router-dom";

const HeaderTransparent = () => {
  const { title, userInfo, iconWhite, setIconWhite } =
    useContext(HeaderContext);
  const { previousLocation, currentLocation } = useContext(LocationContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const showMembershipIcon =
    userInfo?.subscription?.is_active &&
    currentLocation.pathname === "/detalles-membresia";

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (
      ((previousLocation.pathname === "/detalles-membresia" &&
        currentLocation.pathname !== previousLocation?.pathname) ||
        (previousLocation.pathname === "/detalles-plan" &&
          currentLocation.pathname !== previousLocation?.pathname)) &&
      iconWhite
    ) {
      setIconWhite(false);
    }
  }, [iconWhite, currentLocation, previousLocation, setIconWhite]);

  return (
    <>
      <Menu
        open={open}
        toggleDrawer={toggleDrawer}
      />

      <header className={`${classes.Header} ${classes.transparent}`}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ padding: 2, margin: 0 }}
          onClick={toggleDrawer}
          className={`${classes.menuIcon} ${iconWhite && classes.iconWhite}`}
        >
          <MenuIcon />
        </IconButton>
        {title && <h2 className={classes.title}>{title}</h2>}
        {showMembershipIcon ? (
          <div>
            <img
              src={userInfo?.subscription?.icon}
              alt={userInfo?.subscription?.name}
              className={classes.icon}
              style={{ padding: "16px 0px" }}
              onClick={() => navigate("/plan-actual")}
            />
            <a
              id="btnContact"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://wa.me/525543658774?text=¡Hola,%20soy%20${
                userInfo && userInfo.name
              }%20Tengo%20una%20duda...`}
            >
              <SupportIcon
                className={`${classes.icon} ${iconWhite && classes.iconWhite}`}
              />
            </a>
          </div>
        ) : (
          <a
            id="btnContact"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wa.me/525543658774?text=¡Hola,%20soy%20${
              userInfo && userInfo.name
            }%20Tengo%20una%20duda...`}
          >
            <SupportIcon
              className={`${classes.icon} ${iconWhite && classes.iconWhite}`}
            />
          </a>
        )}
      </header>
    </>
  );
};

export default HeaderTransparent;
