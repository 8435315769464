const fullNameSplit = (name) => {
  let firstName, lastName, nameAux;

  nameAux = name.toString().split(" ");

  if (nameAux.length === 0) {
    return;
  }

  firstName = nameAux[0];

  switch (nameAux.length) {
    case 1:
      break;
    case 2:
      lastName = nameAux[1];
      break;
    case 3:
      firstName = `${nameAux[0]} ${nameAux[1]}`;
      lastName = nameAux[2];
      break;
    case 4:
      firstName = `${nameAux[0]} ${nameAux[1]}`;
      lastName = `${nameAux[2]} ${nameAux[3]}`;
      break;
    default:
      firstName = name;
      break;
  }
  return { firstName, lastName };
};

export default fullNameSplit;
