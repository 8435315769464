import React, { useCallback } from "react";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";

import ProfileViewContext from "../context/ProfileViewContext";

const ProfileViewProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const getProfileView = useCallback(async (tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get("plennaAPI", "/api/v2/patients/profile", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const resetPassword = async (tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get("plennaAPI", "/auth/resetPassword", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPatientPhoneNumber = useCallback(async (tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get("plennaAPI", "/auth/getPatientPhoneNumber", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const editProfile = useCallback(async (tokenAuth) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get("plennaAPI", "/auth/editProfile", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const getPatientLaboratories = useCallback(async (tokenAuth, page) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.get(
          "plennaAPI",
          "/health/getPatientLaboratories",
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${tokenAuth.__raw}`,
            },
            queryStringParameters: {
              page,
              limit: 3,
            },
          }
        );
        return resp.data;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const getPrescriptions = async (page) => {
    const id_token = await getIdTokenClaims();
    try {
      const resp = await API.get("plennaAPI", `/health/prescriptions`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
        queryStringParameters: {
          page,
          limit: 3,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getPatientInfo = useCallback(async (token) => {
    try {
      const resp = await API.post("plennaAPI", "/auth/getPatientInfo", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token.__raw}`,
        },
        body: {},
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: resp.data._id || "",
      });

      return resp;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updatePatientInfo = useCallback(async (tokenAuth, body) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.patch("plennaAPI", "/auth/updatePatientInfo", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
          body,
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return (
    <ProfileViewContext.Provider
      value={{
        getProfileView,
        getPatientPhoneNumber,
        editProfile,
        getPatientLaboratories,
        getPrescriptions,
        updatePatientInfo,
        getPatientInfo,
        resetPassword,
      }}
    >
      {children}
    </ProfileViewContext.Provider>
  );
};

export default ProfileViewProvider;
