import React from "react";
import BlogContext from "../context/BlogContext";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";

const BlogProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const getBlogArticleList = async (params) => {
    const id_token = await getIdTokenClaims();
    const queryStringParameters = params;

    return API.get("plennaAPI", `/health/getBlogArticleList`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      queryStringParameters,
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const getCategoryArticleList = async (params) => {
    const id_token = await getIdTokenClaims();
    const queryStringParameters = params;

    return API.get("plennaAPI", `/health/getCategoryArticleList`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      queryStringParameters,
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <BlogContext.Provider
      value={{
        getCategoryArticleList,
        getBlogArticleList,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export default BlogProvider;
