import React, { useContext, useState } from "react";
import { API } from "aws-amplify";
import HomeContext from "../context/HomeContext";
import HeaderContext from "../context/HeaderContext";
import { useAuth0 } from "@auth0/auth0-react";

const HomeProvider = ({ children }) => {
  const { setFum } = useContext(HeaderContext);
  const { getIdTokenClaims } = useAuth0();
  const [loadingTImeline, setLoadingTimeLine] = useState(false);
  const [respGetBabyWeeklyGrowth, setRespgGetBabyWeeklyGrowth] = useState(null);

  const getBabyWeeklyGrowth = async (startDate) => {
    const id_token = await getIdTokenClaims();
    if (id_token !== "") {
      try {
        const resp = await API.get(
          "plennaAPI",
          `/health/getBabyWeeklyGrowth?startDate=${startDate.toISOString()}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${id_token.__raw}`,
            },
          }
        );
        setRespgGetBabyWeeklyGrowth(resp);
        setFum(resp ? resp.data.lastMenstruationDate : "");
        return resp.data;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getHomeData = async () => {
    const id_token = await getIdTokenClaims();

    if (id_token !== "") {
      try {
        const resp = await API.get("plennaAPI", `/health/getHomePage`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${id_token.__raw}`,
          },
        });
        return resp.body;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDetailWeek = async (token, week) => {
    if (week !== "" && token !== null) {
      try {
        const resp = await API.get(
          "plennaAPI",
          `/health/weeklyDetailBabyGrowth?week=${week}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token.__raw}`,
            },
          }
        );
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateGrief = async (token) => {
    return await API.post("plennaAPI", "/auth/grief", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token.__raw}`,
      },
      body: {
        grief: false,
      },
    })
      .then((resp) => {
        console.log(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDataTimeLine = async (timeline_id, limit, quarter, page) => {
    const id_token = await getIdTokenClaims();
    return await API.get("plennaAPI", `/api/v2/timelines`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      queryStringParameters: {
        timeline_id,
        limit,
        quarter,
        page,
      },
    });
  };

  return (
    <HomeContext.Provider
      value={{
        getHomeData,
        getBabyWeeklyGrowth,
        respGetBabyWeeklyGrowth,
        getDetailWeek,
        updateGrief,
        loadingTImeline,
        setLoadingTimeLine,
        getDataTimeLine,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeProvider;
