import React from "react";
import { API } from "aws-amplify";

import MetricsContext from "../context/MetricsContext";

const MetricsProvider = ({ children }) => {
  const getQuestions = async () => {
    try {
      const resp = await API.get("plennaAPI", "/membership/getMetricsInfo", {
        headers: {
          "content-type": "application/json",
        },
        body: {
          name: "Membresía Integral",
        },
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const setMetrics = async (tokenAuth, body) => {
    if (tokenAuth !== "") {
      try {
        const resp = await API.post("plennaAPI", "/membership/setMetricsInfo", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
          body,
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <MetricsContext.Provider
      value={{
        getQuestions,
        setMetrics,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

export default MetricsProvider;
