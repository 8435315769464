import React from "react";

import HeaderLogo from "../../assets/images/Logotipo horizontal/Logotiopo horizontal.png";
import classes from "./Header.module.css";
import PregnancyLogo from "../../assets/images/Logotipo horizontal/LogotipoMaternidad.png";

const HeaderBasic = ({ mode }) => {
  return (
    <header className={`${classes.Header} ${classes.default}`}>
      <a href="/home">
        <img
          src={mode === "pregnancy" ? PregnancyLogo : HeaderLogo}
          alt="Plenna Name Logo"
          className={classes.Logo}
        />
      </a>
    </header>
  );
};

export default HeaderBasic;
