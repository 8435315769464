import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";

const analyticsCustomer = AnalyticsBrowser.load(
  { writeKey: process.env.REACT_APP_CUSTOMERIO_WRITE_KEY },
  {
    integrations: {
      "Customer.io In-App Plugin": {
        siteId: process.env.REACT_APP_CUSTOMERIO_SITE_ID,
      },
    },
  }
);

export default analyticsCustomer;
