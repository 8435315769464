import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { API } from "aws-amplify";
import serviceIsAPackage from "../utils/serviceIsAPackage";

import ScheduleContext from "../context/ScheduleContext";

const queryService = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);

  return parseInt(params.get("serviceId")) || 1;
};

const prevAppointment = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  return params.get("appointmentId");
};

const prevAppointmentStatus = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  return params.get("paid");
};

const queryDoctor = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);

  return params.get("doctor") || "";
};

const queryDate = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);

  const date = params.get("date") ? new Date(params.get("date")) : new Date();
  return date;
};

const queryAppointmentType = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);

  const appointmentType = params.get("followUp") ? "follow-up" : "new schedule";
  return appointmentType;
};

const ScheduleProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const [appointmentType] = useState(queryAppointmentType);
  const [idService, setIdService] = useState(queryService);
  const prevAppointmentId = prevAppointment();
  const prevAppointmentPaid = prevAppointmentStatus();
  const [appointmentId, setAppointmentId] = useState("");
  const [isPackage, setIsPackage] = useState(false);
  const [medicalRecord, setMedicalRecord] = useState("");
  const [laboratories, setLaboratories] = useState([]);
  const [laboratoriesPrice, setLaboratoriesPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [discountLabel, setDiscountLabel] = useState("");
  const [ammountDiscounted, setAmmountDiscounted] = useState(0);
  const [totalPriceToBePaid, setTotalPriceToBePaid] = useState(0);
  const [session, setSession] = useState(0);
  const [numberSessions, setNumberSessions] = useState(1);

  const [date, setDate] = useState(queryDate);
  const [timeSelect, setTimeSelect] = useState("");
  const [appointment, setAppointment] = useState(null);
  const [hourAndDoctorSelect, setHourAndDoctorSelect] = useState({});
  const [doctor, setDoctor] = useState(undefined);
  const [dataPatient, setDataPatient] = useState({});
  const [selectedDatePackage, setSelectedDatePackage] = useState({
    first: "Pendiente",
    second: "Pendiente",
    third: "Pendiente",
    fourth: "Pendiente",
    fifth: "Pendiente",
    sixth: "Pendiente",
    seventh: "Pendiente",
  });
  const [selectedHourPackage, setSelectedHourPackage] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
    seventh: "",
  });

  const [typeformLink, setTypeformLink] = useState("");
  const [currentView, setCurrentView] = useState("calendar");
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
  const [consent, setConsent] = useState("");
  const [terms, setTerms] = useState("");
  const [service, setService] = useState({});
  const [reasonsForAppointment, setReasonsForAppointment] = useState();
  const [reason, setReason] = useState("");
  const [coupon, setCoupon] = useState("");
  const [wantToChangeDoctor, setWantToChangeDoctor] = useState(false);
  const [doctorId] = useState(queryDoctor);
  const [refreshData, setRefreshData] = useState(false);
  const [clinicSelect, setClinicSelect] = useState("");
  const [membership, setMembership] = useState();
  const [dataAppointmentActive, setDataAppointmentActive] = useState(null);
  const [paymentMethodIdSelected, setPaymentMethodIdSelected] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [attemptCounter, setAttemptCounter] = useState(0);

  const [tokenAuth, setTokenAuth] = useState("");

  const tokenAuth0 = getIdTokenClaims();

  const oneSessionServices = [6, 82, 87, 44, 79];
  const twoSessionServices = [7, 83, 88, 80];
  const fourSessionServices = [8, 84, 89, 81, 90];

  const getPatientInfo = async (token) => {
    try {
      const resp = await API.post("plennaAPI", "/auth/getPatientInfo", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token.__raw}`,
        },
        body: {},
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };
  const verifyOnboardingStatus = async () => {
    const id_token = await getIdTokenClaims();
    try {
      const resp = await API.get("plennaAPI", "/auth/verifyOnboardingStatus", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };
  const getAppointmentInfo = useCallback(
    async (appointment) => {
      const id_token = await getIdTokenClaims();

      try {
        const resp = await API.get(
          "plennaAPI",
          `/api/v2/appointments/${appointment}/checkup`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${id_token.__raw}`,
            },
          }
        );
        return resp.data;
      } catch (error) {
        console.log(error);
      }
    },
    [getIdTokenClaims]
  );

  const rescheduleAppointment = async (appointmentId, body) => {
    const id_token = await getIdTokenClaims();

    try {
      const resp = await API.post(
        "plennaAPI",
        `/api/v2/booking/appointments/${appointmentId}/reschedule`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${id_token.__raw}`,
            "Request-Channel": "app-web",
          },
          body,
        }
      );
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const getReasonsForAppointment = () => {
    let payload = localStorage.getItem("reasonsForAppointment");

    if (idService !== 10 && idService !== 53) {
      setReasonsForAppointment(JSON.parse(payload));
    } else {
      setLaboratories(JSON.parse(payload));
    }
    localStorage.removeItem("reasonsForAppointment");
  };

  const getPriceLaboratories = () => {
    const prices = laboratories.map((laboratory) => {
      return laboratory.price;
    });

    const totalPrice = prices.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    setLaboratoriesPrice(totalPrice);
  };

  const getValidationAppointmentActive = async (parameters) => {
    const id_token = await getIdTokenClaims();
    try {
      const resp = await API.get("plennaAPI", "/api/v2/schedules/validations", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
        queryStringParameters: parameters,
      });
      return resp;
    } catch (error) {
      setDataAppointmentActive(JSON.parse(error.request.responseText).data);
    }
  };

  useEffect(() => {
    let payload = localStorage.getItem("reason");

    setReason(JSON.parse(payload));
    localStorage.removeItem("reason");
  }, []);

  useEffect(() => {
    if (serviceIsAPackage(idService) && !prevAppointmentId) {
      setCurrentView("sessionsList");
      setIsPackage(true);
    }
    getReasonsForAppointment();
    // eslint-disable-next-line
  }, [idService]);

  useEffect(() => {
    if (idService === 10 || idService === 53) {
      getPriceLaboratories();
    }
    // eslint-disable-next-line
  }, [laboratories]);

  useEffect(() => {
    tokenAuth0
      .then((resp) => {
        if (resp !== undefined) {
          setTokenAuth(resp);
        }
      })
      .catch((error) => console.log(error));
  }, [tokenAuth0]);

  useEffect(() => {
    if (tokenAuth !== "" || refreshData) {
      getPatientInfo(tokenAuth)
        .then((resp) => {
          setDataPatient(resp.data);

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            userId: resp.data._id || "",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenAuth, refreshData]);

  return (
    <ScheduleContext.Provider
      value={{
        appointmentType,
        reason,
        setReason,
        appointmentId,
        setAppointmentId,
        setMedicalRecord,
        medicalRecord,
        laboratories,
        laboratoriesPrice,
        dataPatient,
        setDataPatient,
        doctorId,
        doctor,
        setDoctor,
        tokenAuth,
        service,
        setService,
        isPackage,
        setIsPackage,
        idService,
        setIdService,
        date,
        setDate,
        timeSelect,
        setTimeSelect,
        appointment,
        setAppointment,
        hourAndDoctorSelect,
        setHourAndDoctorSelect,
        finalPrice,
        setFinalPrice,
        discountLabel,
        setDiscountLabel,
        ammountDiscounted,
        setAmmountDiscounted,
        totalPriceToBePaid,
        setTotalPriceToBePaid,
        typeformLink,
        setTypeformLink,
        currentView,
        setCurrentView,
        paymentMethodSelected,
        setPaymentMethodSelected,
        consent,
        setConsent,
        terms,
        setTerms,
        session,
        setSession,
        selectedDatePackage,
        setSelectedDatePackage,
        selectedHourPackage,
        setSelectedHourPackage,
        reasonsForAppointment,
        setReasonsForAppointment,
        oneSessionServices,
        twoSessionServices,
        fourSessionServices,
        verifyOnboardingStatus,
        setRefreshData,
        prevAppointmentId,
        getAppointmentInfo,
        rescheduleAppointment,
        coupon,
        setCoupon,
        wantToChangeDoctor,
        setWantToChangeDoctor,
        clinicSelect,
        setClinicSelect,
        numberSessions,
        setNumberSessions,
        prevAppointmentPaid,
        membership,
        setMembership,
        getValidationAppointmentActive,
        dataAppointmentActive,
        paymentMethodIdSelected,
        setPaymentMethodIdSelected,
        discountRate,
        setDiscountRate,
        attemptCounter,
        setAttemptCounter,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleProvider;
