import React, { useState } from "react";
import { API } from "aws-amplify";
import FormsContext from "../context/FormsContext";
import { useAuth0 } from "@auth0/auth0-react";

const ids = {
  historial: {
    general: 1,
    ginecologia: 2,
    psicologia: 4,
    sueno: 5,
    nutricion: 8,
    alimentacion: 9,
    obstetricia: 10,
    vacunas: 12,
  },
  maternidad: {
    calculadora: 11,
  },
};

const FormsProvider = ({ children }) => {
  const [saveResponse, setSaveResponse] = useState({});

  const { getIdTokenClaims } = useAuth0();

  const getFormQuestions = async (id, location) => {
    const id_token = await getIdTokenClaims();

    return API.get(
      "plennaAPI",
      `/admin/getMedicalHistoryTemplate?template_id=${ids[location][id]}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
      }
    )
      .then((resp) => {
        return resp.body;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postAnswers = async (body, id, location) => {
    const id_token = await getIdTokenClaims();

    return API.post(
      "plennaAPI",
      `/admin/setMedicalHistory?template_id=${ids[location][id]}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
        body,
      }
    )
      .then((resp) => {
        if (resp.success) {
          return resp.body.form;
        } else {
          return "SaveError";
        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          return "NetworkError";
        } else {
          return "SaveError";
        }
      });
  };

  const postAnswersPregnancyCalculator = async (body, id) => {
    const id_token = await getIdTokenClaims();
    return API.post("plennaAPI", "/health/pregnancyCalculator", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      body,
    })
      .then((resp) => {
        resp.data.selection = Object.keys(body)[0];
        setSaveResponse(resp.data);
        return true;
      })
      .catch((error) => {
        return "MiscalculationError";
      });
  };
  return (
    <FormsContext.Provider
      value={{
        getFormQuestions,
        postAnswers,
        postAnswersPregnancyCalculator,
        saveResponse,
      }}
    >
      {children}
    </FormsContext.Provider>
  );
};

export default FormsProvider;
