import React from "react";

import Loader from "../../../assets/lotties/Loader/loader.json";
import classes from "./Spinner.module.css";
import Lottie from "react-lottie-player";

const spinnerPlenna = ({ detailSpinner, children }) => {
  return (
    <div className={`${classes.container} ${detailSpinner && classes.bGWhite}`}>
      <Lottie loop animationData={Loader} play className={classes.width} />
      {detailSpinner && children}
    </div>
  );
};

export default spinnerPlenna;
