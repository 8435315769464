import React, { useState } from "react";
import { API } from "aws-amplify";

import OnboardingContext from "../context/OnboardingContext";

const OnboardingProvider = ({ children }) => {
  const [currentView, setCurrentView] = useState(undefined);
  const [countStepper, setCountStepper] = useState(0);

  const getQuestions = async () => {
    try {
      const resp = await API.get("plennaAPI", "/getOnboardingInfo", {
        headers: {
          "content-type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRvIjoiJEMmRilKQE5jUmZValhuMnI1dTh4IUElRCpHLUthUGQifQ.deD-N_984AlyQ3G1U6E0u0WznUkp5f92PGUulO3EYQA",
        },
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const changeCountStepper = (type, step) => {
    switch (type) {
      case "forward":
        return setCountStepper(step + 1);
      case "backward":
        return setCountStepper(step - 1);
      default:
        break;
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        getQuestions,
        currentView,
        setCurrentView,
        countStepper,
        setCountStepper,
        changeCountStepper,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
