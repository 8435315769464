import React from "react";
import SymptomsContext from "../context/SymptomsContext";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";

const SymptomsProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const getSymptoms = async (queryStringParameters) => {
    if (Object.keys(queryStringParameters).length === 0) {
      return {};
    }
    const id_token = await getIdTokenClaims();

    try {
      const resp = await API.get("plennaAPI", "/health/symptoms", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
        queryStringParameters,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getDetailSymptoms = async (id) => {
    const id_token = await getIdTokenClaims();

    try {
      const resp = await API.get("plennaAPI", `/health/symptoms?id=${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  const postSymptoms = async (id, answer) => {
    const id_token = await getIdTokenClaims();
    console.log("Execute");
    return await API.post("plennaAPI", "/health/symptoms", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
      body: {
        id: id,
        response: answer,
      },
    });
  };
  return (
    <SymptomsContext.Provider
      value={{ getSymptoms, getDetailSymptoms, postSymptoms }}
    >
      {children}
    </SymptomsContext.Provider>
  );
};

export default SymptomsProvider;
