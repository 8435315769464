import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import SildeAnimation from "../SlideAnimation/SlideAnimation";

const ContentAnimation = (props) => {
  const { maxheight } = props;
  const containerRef = useRef(null);
  return (
    <Box
      sx={{
        height: maxheight || "100vh",
        width: "100%",
        display: "flex",
        borderRadius: 1,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={containerRef}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={5}
        >
          <Box sx={{ width: "100%" }}>
            <SildeAnimation props={props} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentAnimation;
