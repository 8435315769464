import React from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Grid from "@mui/material/Grid";
import classes from "./GoTo.module.css";

const Forward = ({
  title,
  hideArrow,
  xs = 12,
  sm = 12,
  lg = 12,
  className,
  icon,
}) => {
  return (
    <Grid
      container
      className={`${classes.buttonContainer} ${className}`}
    >
      <Grid
        item
        xs={xs}
        sm={sm}
        lg={lg}
      >
        <p className={classes.titleForward}>
          {icon && <span>{icon}</span>}
          {title}
        </p>
      </Grid>
      {hideArrow ? null : (
        <ArrowForwardIosOutlinedIcon className={classes.buttonForwardIcon} />
      )}
    </Grid>
  );
};

export default Forward;
