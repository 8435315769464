import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { API } from "aws-amplify";
import format from "date-fns/format";

import HeaderContext from "../context/HeaderContext";
import Backdrop from "../components/UI/Backdrop/Backdrop";
import Spinner from "../components/UI/Spinner/SpinnerPlenna";
import analytics from "../analytics";
import analyticsCustomer from "../customer";
import { getAge } from "../utils/formatDates";

const modes = {
  "Cuidarme y conocerme": "default",
  "Quedar embarazada": "default",
  "Seguir mi embarazo": "pregnancy",
};

const HeaderProvider = ({ children, setMode }) => {
  const [headerType, setHeaderType] = useState("CenterBasic");
  const [variant, setVariant] = useState("");
  const [title, setTitle] = useState("");
  const [healthTeam, setHealthTeam] = useState([]);
  const [fum, setFum] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: "",
    photo: "",
    mode: "",
  });
  const [loading, setLoading] = useState(false);
  const { getIdTokenClaims, isAuthenticated, user } = useAuth0();
  const [iconWhite, setIconWhite] = useState(false);

  const getPatientInfo = async () => {
    const id_token = await getIdTokenClaims();
    try {
      const resp = await API.post("plennaAPI", "/auth/getPatientInfo", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${id_token.__raw}`,
        },
        body: {},
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const getQuarter = (week) => {
    if (week <= 12) return 1;
    if (week <= 24) return 2;
    return 3;
  };

  const updatePatientInfo = () => {
    getPatientInfo().then((resp) => {
      const data = resp.data;
      setHealthTeam(data.health_team);
      setUserInfo({
        email: user.email,
        grief: data.grief,
        name: data.given_name,
        objective: data.objective,
        stage: data.stage,
        photo: user.picture,
        logins: user.loginCount,
        mode: modes[data.objective] || "default",
        gestationalWeek: data.gestational_week,
        gestationalDay: data.gestational_day,
        quarter: getQuarter(parseInt(data.gestational_week)),
        illustration: data.illustration,
        firstName: data.given_name,
        lastName: data.family_name,
        phone: data.phone_number,
        country: data.country,
        state: data.state,
        postalCode: data.zip_code,
        birthdate: data.birthdate
          ? format(new Date(data.birthdate), "dd/MM/yyyy")
          : "",
        age: data.birthdate ? getAge(new Date(data.birthdate)) : "",
        showClinics: data.multiclinic,
        feature_flags: data?.feature_flags,
        subscription: data?.subscription,
      });
      analytics.identify(user.app_metadata.patient_id, {
        firstName: data.given_name,
        lastName: data.family_name,
        email: user.email,
        phone: data.phone_number,
        logins: user.loginCount,
        address: {
          country: data.country,
          state: data.state,
          postalCode: data.zip_code,
        },
        objective: data.objective,
        birthdate: data.birthdate
          ? format(new Date(data.birthdate), "dd/MM/yyyy")
          : "",
        age: data.birthdate ? getAge(new Date(data.birthdate)) : "",
      });
      analyticsCustomer.identify(user.app_metadata.patient_id, {
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email: user.email,
      });
      setLoading(false);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: resp.data._id || "",
      });
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      updatePatientInfo();
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (user && userInfo && fum !== "") {
      analytics.identify(user.app_metadata.patient_id, {
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email: user.email,
        phone: userInfo.phone_number,
        birthdate: userInfo.birthdate,
        age: userInfo.birthdate,
        logins: user.loginCount,
        address: {
          country: userInfo.country,
          state: userInfo.state,
          postalCode: userInfo.zip_code,
        },
        objective: userInfo.objective,
        fum: format(new Date(fum), "dd/MM/yyyy"),
      });
      analyticsCustomer.identify(user.app_metadata.patient_id, {
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email: user.email,
      });
    }
  }, [userInfo, fum, user]);

  useEffect(() => {
    document.documentElement.setAttribute("mode", userInfo.mode);
    setMode(userInfo.mode);
  }, [userInfo, setMode]);

  return (
    <HeaderContext.Provider
      value={{
        headerType,
        setHeaderType,
        variant,
        setVariant,
        title,
        setTitle,
        userInfo,
        setUserInfo,
        healthTeam,
        updatePatientInfo,
        setFum,
        iconWhite,
        setIconWhite,
      }}
    >
      {loading ? (
        <Backdrop show={true}>
          <Spinner />
        </Backdrop>
      ) : (
        children
      )}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
