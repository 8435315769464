const tabTitles = [
  { title: "Inicio | Portal Plenna", route: "/home" },
  {
    title: "Personaliza tu experiencia | Portal Plenna",
    route: "/onboarding",
  },
  { title: "Historial general | Portal Plenna", route: "/historialGeneral" },
  {
    title: "Historial de psicología | Portal Plenna",
    route: "/historialPsico",
  },
  { title: "Historial de sueño | Portal Plenna", route: "/historialSueno" },
  {
    title: "Historial ginecológico | Portal Plenna",
    route: "/historialgineco",
  },
  {
    title: "Historial de nutrición | Portal Plenna",
    route: "/historialNutri",
  },
  { title: "Mis citas | Portal Plenna", route: "/citas" },
  { title: "Mis citas | Portal Plenna", route: "/citas?view=citas" },

  { title: "Agenda una cita | Portal Plenna", route: "/especialidades" },
  {
    title: "Ginecología - Servicios | Portal Plenna",
    route: "/lista-servicios?category=Salud ginecológica",
  },
  {
    title: "Psicología - Servicios | Portal Plenna",
    route: "/lista-servicios?category=Psicología y balance",
  },
  {
    title: "Nutrición - Servicios | Portal Plenna",
    route: "/lista-servicios?category=Nutrición",
  },
  {
    title: "Vacunas - Servicios | Portal Plenna",
    route: "/lista-servicios?category=Vacunas",
  },
  {
    title: "Laboratorios | Portal Plenna",
    route: "/detalle-servicio?service_id=10&direct=true",
  },
  {
    title: "Medicina general | Portal Plenna",
    route: "/detalle-servicio?service_id=9&direct=true",
  },
  {
    title: "Consulta ginecológica | Portal Plenna",
    route: "/detalle-servicio?service_id=1",
  },
  {
    title: "Consulta ginecológica virtual | Portal Plenna",
    route: "/detalle-servicio?service_id=2",
  },
  {
    title: "Check-up básico | Portal Plenna",
    route: "/detalle-servicio?service_id=3",
  },
  {
    title: "Check-up plus | Portal Plenna",
    route: "/detalle-servicio?service_id=4",
  },
  {
    title: "Anticonceptivos de larga duración | Portal Plenna",
    route: "/detalle-servicio?service_id=5",
  },
  {
    title: "1 sesión - Psicología | Portal Plenna",
    route: "/detalle-servicio?service_id=6",
  },
  {
    title: "2 sesiones - Psicología | Portal Plenna",
    route: "/detalle-servicio?service_id=7",
  },
  {
    title: "4 sesiones - Psicología | Portal Plenna",
    route: "/detalle-servicio?service_id=8",
  },
  {
    title: "1 sesión - Nutrición | Portal Plenna",
    route: "/detalle-servicio?service_id=44",
  },
  {
    title: "2 sesiones - Nutrición | Portal Plenna",
    route: "/detalle-servicio?service_id=45",
  },
  {
    title: "Vacuna de Herpes Zóster | Portal Plenna",
    route: "/detalle-servicio?service_id=11",
  },
  {
    title: "Vacuna de VPH | Portal Plenna",
    route: "/detalle-servicio?service_id=12",
  },
  {
    title: "Agendar - Laboratorios | Portal Plenna",
    route: "/calendario?serviceId=10",
  },
  {
    title: "Agendar - Medicina general | Portal Plenna",
    route: "/calendario?serviceId=9",
  },
  {
    title: "Agendar - Consulta ginecológica | Portal Plenna",
    route: "/calendario?serviceId=1",
  },
  {
    title: "Agendar - Consulta ginecológica virtual | Portal Plenna",
    route: "/calendario?serviceId=2",
  },
  {
    title: "Agendar - Check-up básico | Portal Plenna",
    route: "/calendario?serviceId=3",
  },
  {
    title: "Agendar - Check-up plus | Portal Plenna",
    route: "/calendario?serviceId=4",
  },
  {
    title: "Agendar - Anticonceptivos de larga duración | Portal Plenna",
    route: "/calendario?serviceId=5",
  },
  {
    title: "Agendar - 1 sesión - Psicología | Portal Plenna",
    route: "/calendario?serviceId=6",
  },
  {
    title: "Agendar - 2 sesiones - Psicología | Portal Plenna",
    route: "/calendario?serviceId=7",
  },
  {
    title: "Agendar - 4 sesiones - Psicología | Portal Plenna",
    route: "/calendario?serviceId=8",
  },
  {
    title: "Agendar - 1 sesión - Nutrición | Portal Plenna",
    route: "/calendario?serviceId=44",
  },
  {
    title: "Agendar - 2 sesiones - Nutrición | Portal Plenna",
    route: "/calendario?serviceId=45",
  },
  {
    title: "Agendar - Vacuna de Herpes Zóster | Portal Plenna",
    route: "/calendario?serviceId=11",
  },
  {
    title: "Agendar - Vacuna de VPH | Portal Plenna",
    route: "/calendario?serviceId=12",
  },
  { title: "Perfil | Portal Plenna", route: "/perfil" },
  { title: "Editar perfil | Portal Plenna", route: "/editar-perfil" },
  {
    title: "Mi historial clínico | Portal Plenna",
    route: "/historial-clinico",
  },
  { title: "Mis recetas | Portal Plenna", route: "/mis-recetas" },
  { title: "Mis laboratorios | Portal Plenna", route: "/mis-laboratorios" },
  { title: "Mis métricas | Portal Plenna", route: "/metricas" },
  { title: "Facturación | Portal Plenna", route: "/facturacion" },
  { title: "Contacto y asistencia | Portal Plenna", route: "/contacto" },
  { title: "Legales | Portal Plenna", route: "/legales" },
];

export default tabTitles;
