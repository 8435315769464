import React, { useReducer, useState } from "react";
import BirthControlTestContext from "../context/BirthControlTestContext";
import { API } from "aws-amplify";

const BirthControlTestProvider = ({ children }) => {
  const initialFormState = {
    organized: null,
    childrenPlanning: null,
    acceptsHormones: null,
    menstrualPeriod: null,
    smokes: null,
    cigarettesQty: null,
    age: null,
    diseases: null,
    weight: null,
    height: null,
    medicalRecord: null,
  };

  const formReducer = (state, action) => {
    let stateToReturn = { ...state };

    switch (action.type) {
      case "organized":
        stateToReturn["organized"] = action.payload;
        break;
      case "childrenPlanning":
        stateToReturn["childrenPlanning"] = action.payload;
        break;
      case "efficiency":
        stateToReturn["efficiency"] = action.payload;
        break;
      case "acceptsHormones":
        stateToReturn["acceptsHormones"] = action.payload;
        break;
      case "menstrualPeriod":
        stateToReturn["menstrualPeriod"] = action.payload;
        break;
      case "smokes":
        stateToReturn["cigarettesQty"] = action.payload.cigarettesQty;
        stateToReturn["smokes"] = action.payload.smokes;
        break;
      case "age":
        stateToReturn["age"] = action.payload;
        break;
      case "diseases":
        stateToReturn["diseases"] = action.payload;
        break;
      case "weight":
        stateToReturn["weight"] = action.payload;
        break;
      case "height":
        stateToReturn["height"] = action.payload;
        break;
      case "medicalRecord":
        stateToReturn["medicalRecord"] = action.payload;
        break;
      default:
        break;
    }

    return stateToReturn;
  };
  const stepReducer = (state, action) => {
    switch (action.type) {
      case "forward":
        return state + 1;
      case "backward":
        return state - 1;
      default:
        break;
    }
  };

  const [formStep, setStep] = useReducer(stepReducer, 0);
  const [formToSend, setFormState] = useReducer(formReducer, initialFormState);
  const [userState, setUserState] = useState({});

  const PostSendForm = (body) => {
    return API.post("plennaAPI", "/health/birthControlTest", {
      headers: {
        "content-type": "application/json",
      },
      body,
    })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      });
  };

  const PostRegisterLead = (body) => {
    return API.post("plennaAPI", "/marketing/registerLead", {
      headers: {
        "content-type": "application/json",
      },
      body,
    })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const PostStoreTestInfo = (body) => {
    return API.post("plennaAPI", "/marketing/storeTestInfo", {
      headers: {
        "content-type": "application/json",
      },
      body,
    })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPhoneCodes = () => {
    return API.get("plennaAPI", "/getCountryPhoneCodes")
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <BirthControlTestContext.Provider
      value={{
        PostSendForm,
        PostRegisterLead,
        PostStoreTestInfo,
        formStep,
        setStep,
        formToSend,
        setFormState,
        userState,
        setUserState,
        getPhoneCodes,
      }}
    >
      {children}
    </BirthControlTestContext.Provider>
  );
};

export default BirthControlTestProvider;
