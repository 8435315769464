import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useMediaQuery from "@mui/material/useMediaQuery";

import ArticleDetailContext from "../context/ArticleDetailContext";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsAppIcon,
} from "../components/UI/Icons/Icons";

const ArticleDetailProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const { id } = useParams();
  const [tokenAuth, setTokenAuth] = useState("");
  const [data, setData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const viewport = useMediaQuery("(min-width:900px)") ? "desktop" : "mobile";

  const tokenAuth0 = getIdTokenClaims();
  const { href } = window.location;
  const share = [
    {
      text: "Facebook",
      icon: <FacebookIcon />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${href}`,
      action: "",
    },
    {
      text: "Twitter",
      icon: <TwitterIcon />,
      url: `https://twitter.com/intent/tweet?url=${href}`,
      action: "",
    },
    {
      text: "WhatsApp",
      icon: <WhatsAppIcon />,
      url: `https://api.whatsapp.com/send?text=${href}`,
      action: "share/whatsapp/share",
    },
  ];

  useEffect(() => {
    tokenAuth0
      .then((resp) => {
        if (resp !== undefined) {
          setTokenAuth(resp);
        }
      })
      .catch((error) => console.log(error));
  }, [tokenAuth0]);

  useEffect(() => {
    setShowLoader(true);
    if (tokenAuth !== "") {
      return API.get(
        "plennaAPI",
        `/health/getArticleDetail?id=${id}&viewport=${viewport}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${tokenAuth.__raw}`,
          },
        }
      )
        .then((resp) => {
          setData(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenAuth, id, viewport]);

  return (
    <ArticleDetailContext.Provider
      value={{ data, share, showLoader, setShowLoader }}
    >
      {children}
    </ArticleDetailContext.Provider>
  );
};

export default ArticleDetailProvider;
