import React from "react";

import classes from "./Button.module.css";

const button = (props) => {
  let themeButtonClass;

  switch (props.theme) {
    case "warm-dark":
      themeButtonClass = classes.warm__dark;
      break;
    case "warm-light":
      themeButtonClass = classes.warm__light;
      break;
    case "warm-orange":
      themeButtonClass = classes.warm__orange;
      break;
    case "warm-pink":
      themeButtonClass = classes.warm__pink;
      break;
    case "warm-purple":
      themeButtonClass = classes.warm__purple;
      break;
    case "cold-light":
      themeButtonClass = classes.cold__light;
      break;
    case "cold-dark":
      themeButtonClass = classes.cold__dark;
      break;
    case "teal-light":
      themeButtonClass = classes.teal__light;
      break;
    case "inverted-teal-light":
      themeButtonClass = classes.teal__light__inverted;
      break;
    case "teal-dark":
      themeButtonClass = classes.teal__dark;
      break;
    default:
      themeButtonClass = classes.warm__light;
      break;
  }

  return (
    <button
      name={props.name}
      className={`${classes.plennaButton} ${themeButtonClass} ${
        props.className ? props.className : null
      }`}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default button;
