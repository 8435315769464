import React from "react";
import CounselingContext from "../context/CounselingContext";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";

const CounselingProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const getCounseling = async () => {
    const id_token = await getIdTokenClaims();

    return API.get("plennaAPI", "/api/v2/counseling", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${id_token.__raw}`,
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <CounselingContext.Provider value={{ getCounseling }}>
      {children}
    </CounselingContext.Provider>
  );
};

export default CounselingProvider;
