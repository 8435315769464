import React, { useState } from "react";

import PregnancyLossContext from "../context/PregnancyLossContext";

const PregnancyLossProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showView, setShowView] = useState("default");

  return (
    <PregnancyLossContext.Provider
      value={{
        showView,
        setShowView,
        showLoader,
        setShowLoader,
      }}
    >
      {children}
    </PregnancyLossContext.Provider>
  );
};

export default PregnancyLossProvider;
