import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useAuth0 } from "@auth0/auth0-react";

import BirthFormContext from "../context/BirthFormContext";
import fullNameSplit from "../utils/fullNameSplit";

const BirthFormProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const [tokenAuth, setTokenAuth] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [data, setData] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [template, setTemplate] = useState(null);
  const [formResults, setFormResults] = useState([
    {
      baby_name: "",
      pregnancy_type: "",
      newborn_sex: "",
      newborn_weight: 0,
      newborn_weight_measure: "kg",
    },
  ]);

  const [congratsView, setCongratsView] = useState(false);
  const [congratsImages, setCongratsImages] = useState([]);

  const tokenAuth0 = getIdTokenClaims();

  const saveDataBorns = async (allResponses) => {
    setShowLoader(true);
    const { multiple, new_borns } = allResponses;
    const year = `${allResponses.birth_date.getFullYear()}`;
    const month = `${allResponses.birth_date.getMonth() + 1}`;
    const day = `${allResponses.birth_date.getDate()}`;

    return await API.patch("plennaAPI", "/health/newbornRegistry", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${tokenAuth.__raw}`,
      },
      body: {
        multiple,
        birth_date: [year, month, day],
        new_borns,
      },
    }).then((resp) => {
      if (resp.success) {
        setShowLoader(false);
        setCongratsView(true);
        setCongratsImages(resp.data);
      }
    });
  };

  const getBabyNames = () => {
    const names = formResults.map(
      (baby) => fullNameSplit(baby.baby_name).firstName
    );
    switch (names.length) {
      case 1:
        return names[0];
      case 2:
        return names.join(" y ");
      default:
        names.splice(names.length - 1, 0, "y ");
        let namesJoin = names.join(", ");
        let splitNames = namesJoin.split(", y , ");
        splitNames.splice(splitNames.length - 1, 0, "y");

        return splitNames.join(" ");
    }
  };

  useEffect(() => {
    tokenAuth0
      .then((resp) => {
        if (resp !== undefined) {
          setTokenAuth(resp);
        }
      })
      .catch((error) => console.log(error));
  }, [tokenAuth0]);

  useEffect(() => {
    setShowLoader(true);
    if (tokenAuth !== "") {
      return API.get("plennaAPI", `/health/newbornRegistry`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${tokenAuth.__raw}`,
        },
      })
        .then((resp) => {
          setData(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tokenAuth]);

  return (
    <BirthFormContext.Provider
      value={{
        data,
        setData,
        showLoader,
        setShowLoader,
        questions,
        setQuestions,
        template,
        setTemplate,
        formResults,
        setFormResults,
        saveDataBorns,
        congratsView,
        congratsImages,
        getBabyNames,
      }}
    >
      {children}
    </BirthFormContext.Provider>
  );
};

export default BirthFormProvider;
