import { daysCap, monthsCap } from "./dictionaries";

const formatDateToMexicoTimeZone = (date) => {
  date.setTime(
    date.getTime() + date.getTimezoneOffset() * 60000 - 6 * 60 * 60000
  );
  return date;
};

export const standarizeDate = (isoStringDate) => {
  try {
    return new Date(`${isoStringDate.substring(0, 10)}T06:00:00.000Z`);
  } catch {
    return -1;
  }
};

export const getAge = (birthdate) => {
  const today = new Date();
  let age = today.getFullYear() - birthdate.getFullYear();
  const monthsDifference = today.getMonth() - birthdate.getMonth();

  if (
    monthsDifference < 0 ||
    (monthsDifference === 0 && today.getDate() < birthdate.getDate())
  ) {
    age--;
  }

  return age;
};

export const capitalizeMonths = (text) => {
  let newString = text;
  Object.keys(monthsCap).forEach((month) => {
    if (text.includes(month)) {
      newString = newString.replace(month, monthsCap[month]);
    }
  });
  return newString;
};

export const capitalizeDays = (text) => {
  let newString = text;
  Object.keys(daysCap).forEach((day) => {
    if (text.includes(day)) {
      newString = newString.replace(day, daysCap[day]);
    }
  });
  return newString;
};

export const formatMeridians = (text) => {
  let newString = text;
  newString = newString.replace("a. m.", "am").replace("p. m.", "pm");
  return newString;
};

export const formatStringDate = (stringDate) => {
  return formatMeridians(capitalizeMonths(capitalizeDays(stringDate)));
};

export default formatDateToMexicoTimeZone;
