import React, { useContext } from "react";

import HeaderNone from "./HeaderNone";
import HeaderBasic from "./HeaderBasic";
import HeaderTransparent from "./HeaderTransparent";
import HeaderSolid from "./HeaderSolid";
import HeaderContext from "../../context/HeaderContext";

const Header = () => {
  const { headerType, userInfo } = useContext(HeaderContext);

  switch (headerType) {
    case "Basic":
      return <HeaderBasic mode={userInfo.mode} />;
    case "DisplayNone":
      return <HeaderNone />;
    case "Transparent":
      return <HeaderTransparent />;
    case "Solid":
      return <HeaderSolid />;
    default:
      return <HeaderBasic mode={userInfo.mode} />;
  }
};

export default Header;
