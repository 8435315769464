import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Backdrop from "../components/UI/Backdrop/Backdrop";
import Signup from "../containers/Signup/Signup";
import OnboardingProvider from "../providers/OnboardingProvider";
import ScheduleProvider from "../providers/ScheduleProvider";
import BirthControlTestProvider from "../providers/BirthControlTestProvider";
import FormsProvider from "../providers/FormsProvider";
import AppointmentsProvider from "../providers/AppointmentsProvider";
import ProfileViewProvider from "../providers/ProfileViewProvider";
import MetricsProvider from "../providers/MetricsProvider";
import SpecialitiesProvider from "../providers/SpecialitiesProvider";
import PsychologicalAssessmentProvider from "../providers/PsychologicalAssessmentProvider";
import HomeProvider from "../providers/HomeProvider";
import ArticleDetailProvider from "../providers/ArticleDetailProvider";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Error from "../containers/RenderFormQuestions/Error/Error";
import BlogProvider from "../providers/BlogProvider";
import SymptomsProvider from "../providers/SymptomsProvider";
import ParametersProvider from "../providers/ParametersProvider";
import BirthFormProvider from "../providers/BirthFormProvider";
import PregnancyLossProvider from "../providers/PregnancyLossProvider";
import TimelineProvider from "../providers/TimelineProvider";
import CounselingProvider from "../providers/CounselingProvider";

const SymptomDetail = React.lazy(() =>
  import("../containers/Symptoms/SymptomDetail/SymptomDetail")
);

const CommonSymptoms = React.lazy(() =>
  import("../containers/Symptoms/CommonSymptoms/CommonSymptoms")
);

const AbnormalSymptoms = React.lazy(() =>
  import("../containers/Symptoms/AbnormalSymptoms/AbnormalSymptoms")
);

const EmergencySymptoms = React.lazy(() =>
  import("../containers/Symptoms/EmergencySymptoms/EmergencySymptoms")
);

const BlogHome = React.lazy(() => import("../containers/BlogHome/BlogHome"));

const BirthControlTest = React.lazy(() =>
  import("../containers/BirthControlTest/BirthControlTest")
);
const AccountDetail = React.lazy(() =>
  import("../containers/AccountDetail/AccountDetail")
);

const Login = React.lazy(() => import("../containers/Login/Login"));

const Schedule = React.lazy(() => import("../containers/Schedule/Schedule"));

const Onboarding = React.lazy(() =>
  import("../containers/Onboarding/Onboarding")
);

const Results = React.lazy(() => import("../containers/Results/Results"));

const ErrorOnSchedule = React.lazy(() =>
  import("../containers/Schedule/ErrorOnSchedule/ErrorOnSchedule")
);

const ScheduleConfirmationView = React.lazy(() =>
  import("../containers/Schedule/ConfirmationView/ConfirmationView")
);

const RescheduleConfirmationView = React.lazy(() =>
  import("../containers/Schedule/ConfirmationView/RescheduleConfirmationView")
);

const Bienvenida = React.lazy(() =>
  import("../containers/Bienvenida/Bienvenida")
);

const AppointmentSummary = React.lazy(() =>
  import("../containers/AppointmentSummary/AppointmentSummary")
);

const Metrics = React.lazy(() => import("../containers/Metrics/Metrics"));

const Appointments = React.lazy(() =>
  import("../containers/Appointments/Appointments")
);
const Profile = React.lazy(() =>
  import("../containers/Profile/Inicio/Profile")
);
const Legales = React.lazy(() =>
  import("../containers/Profile/Legales/Legales")
);
const Contacto = React.lazy(() =>
  import("../containers/Profile/Contacto/Contacto")
);
const Facturacion = React.lazy(() =>
  import("../containers/Profile/Facturacion/Facturacion")
);
const HistorialClinico = React.lazy(() =>
  import("../containers/Profile/HistorialClinico/HistorialClinico")
);
const ConfigCuenta = React.lazy(() =>
  import("../containers/Profile/ConfigCuenta/ConfigCuenta")
);
const MisRecetas = React.lazy(() =>
  import("../containers/Profile/MisRecetas/MisRecetas")
);

const EditarPerfil = React.lazy(() =>
  import("../containers/Profile/EditarPerfil/EditarPerfil")
);
const MisLaboratorios = React.lazy(() =>
  import("../containers/Profile/MisLaboratorios/MisLaboratorios")
);
const PsychologicalAssessment = React.lazy(() =>
  import("../containers/PsychologicalAssessment/PsychologicalAssessment")
);

const PsychologistAssignment = React.lazy(() =>
  import("../containers/PsychologistAssignment/PsychologistAssignment")
);

const Checkredirect = React.lazy(() =>
  import("../containers/Login/Checkredirect")
);

const Home = React.lazy(() => import("../containers/Home/Home"));

const Specialities = React.lazy(() =>
  import("../containers/Specialities/Specialities/Specialities")
);
const ServicesList = React.lazy(() =>
  import("../containers/Specialities/ServicesList/ServicesList")
);
const ServiceDetail = React.lazy(() =>
  import("../containers/Specialities/ServiceDetail/ServiceDetail")
);

const Record = React.lazy(() => import("../containers/Records/Record"));

const ArticleDetail = React.lazy(() =>
  import("../containers/Blog/ArticleDetail")
);

const PerinatalCtaBanner = React.lazy(() =>
  import("../containers/Pregnancy/PerinatalCtaBanner/PerinatalCtaBanner")
);

const PregnancyCalculator = React.lazy(() =>
  import("../containers/PregnancyCalculator/PregnancyCalculator")
);
const WeeklyDetail = React.lazy(() =>
  import("../containers/WeeklyDetail/WeeklyDetail")
);

const TargetSelection = React.lazy(() =>
  import("../containers/TargetSelection/TargetSelection")
);

const Parameters = React.lazy(() =>
  import("../containers/Parameters/Parameters")
);

const BirthForm = React.lazy(() => import("../containers/BirthForm/BirthForm"));

const PregnancyLoss = React.lazy(() =>
  import("../containers/PregnancyLoss/PregnancyLoss")
);

const TimelineGuide = React.lazy(() =>
  import("../containers/TimelineGuide/TimelineGuide")
);

const MembershipDetails = React.lazy(() =>
  import("../containers/MembershipDetails/MembershipDetails")
);

const SingUpMembership = React.lazy(() =>
  import("../containers/TimelineGuide/SignUpMembership/SignUpMembership")
);

const WelcomeMembership = React.lazy(() =>
  import("../containers/TimelineGuide/components/WelcomeMembership")
);

const Counseling = React.lazy(() =>
  import("../containers/Counseling/Counseling")
);

const CurrentPlan = React.lazy(() =>
  import("../containers/CurrentPlan/CurrentPlan")
);

const PlanDetails = React.lazy(() =>
  import("../containers/PlanDetails/PlanDetails")
);

const routes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route
        exact
        path="/testAnticonceptivos"
        element={
          <Suspense fallback={<Backdrop show />}>
            <BirthControlTestProvider>
              <BirthControlTest props />
            </BirthControlTestProvider>
          </Suspense>
        }
      />
      <Route
        exact
        path="/errorRecord"
        element={<Error errorType={"SaveError"}></Error>}
      />
      <Route
        exact
        path="/signup"
        element={
          <Suspense fallback={<Backdrop show />}>
            <Signup props />
          </Suspense>
        }
      />
      <Route
        exact
        path="/login"
        element={
          <Suspense fallback={<Backdrop show />}>
            <Login props />
          </Suspense>
        }
      />

      {/* Private Routes */}
      <Route element={<PrivateRoute />}>
        <Route
          exact
          path="/blog/:view"
          element={
            <Suspense fallback={<Backdrop show />}>
              <BlogProvider>
                <BlogHome></BlogHome>
              </BlogProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="maternidad/sintoma/:id"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SymptomsProvider>
                <SymptomDetail></SymptomDetail>
              </SymptomsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="maternidad/sintomas-comunes/:quarter"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SymptomsProvider>
                <CommonSymptoms></CommonSymptoms>
              </SymptomsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="maternidad/sintomas-anormales"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SymptomsProvider>
                <AbnormalSymptoms />
              </SymptomsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="maternidad/sintomas-urgencia"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SymptomsProvider>
                <EmergencySymptoms />
              </SymptomsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/blog"
          element={
            <Navigate
              replace
              to="/blog/semanas"
            />
          }
        />
        <Route
          exact
          path="/home"
          element={
            <Suspense fallback={<Backdrop show />}>
              <HomeProvider>
                <Home props />
              </HomeProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/cita/:id"
          element={
            <Suspense fallback={<Backdrop show />}>
              <AppointmentsProvider>
                <AppointmentSummary props />
              </AppointmentsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/cita/:appointmentId/resultados/:laboratoryId/:entrypoint"
          element={
            <Suspense fallback={<Backdrop show />}>
              <AppointmentsProvider>
                <Results props />
              </AppointmentsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/historial/:id"
          element={
            <Suspense fallback={<Backdrop show />}>
              <FormsProvider>
                <Record props />
              </FormsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/maternidad/consultaPerinatal"
          element={
            <Suspense fallback={<Backdrop show />}>
              <PerinatalCtaBanner />
            </Suspense>
          }
        />
        <Route
          exact
          path="/maternidad/:id"
          element={
            <Suspense fallback={<Backdrop show />}>
              <FormsProvider>
                <PregnancyCalculator props />
              </FormsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/historialGeneral"
          element={
            <Navigate
              replace
              to="/historial/general"
            />
          }
        />
        <Route
          exact
          path="/historialNutri"
          element={
            <Navigate
              replace
              to="/historial/nutricion"
            />
          }
        />
        <Route
          exact
          path="/historialGineco"
          element={
            <Navigate
              replace
              to="/historial/ginecologia"
            />
          }
        />
        <Route
          exact
          path="/historialPsico"
          element={
            <Navigate
              replace
              to="/historial/psicologia"
            />
          }
        />
        <Route
          exact
          path="/historialSueno"
          element={
            <Navigate
              replace
              to="/historial/sueno"
            />
          }
        />
        <Route
          exact
          path="/calendario"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ScheduleProvider>
                <Schedule props />
              </ScheduleProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/errorAgenda"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ErrorOnSchedule props />
            </Suspense>
          }
        />
        <Route
          exact
          path="/confirmacionAgendar"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ScheduleProvider>
                <ScheduleConfirmationView props />
              </ScheduleProvider>
            </Suspense>
          }
        />{" "}
        <Route
          exact
          path="/confirmacionreagendar"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ScheduleProvider>
                <RescheduleConfirmationView props />
              </ScheduleProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/metricas"
          element={
            <Suspense fallback={<Backdrop show />}>
              <MetricsProvider>
                <Metrics />
              </MetricsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/citas"
          element={
            <Suspense fallback={<Backdrop show />}>
              <AppointmentsProvider>
                <Appointments />
              </AppointmentsProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/onboarding"
          element={
            <Suspense fallback={<Backdrop show />}>
              <OnboardingProvider>
                <Onboarding />
              </OnboardingProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/canalizacionPsico"
          element={
            <Suspense fallback={<Backdrop show />}>
              <PsychologicalAssessmentProvider>
                <PsychologicalAssessment />
              </PsychologicalAssessmentProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/asignacionPsicologa"
          element={
            <Suspense fallback={<Backdrop show />}>
              <PsychologistAssignment />
            </Suspense>
          }
        />
        <Route
          exact
          path="/perfil"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ProfileViewProvider>
                <Profile />
              </ProfileViewProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/legales"
          element={
            <Suspense fallback={<Backdrop show />}>
              <Legales />
            </Suspense>
          }
        />
        <Route
          exact
          path="/contacto"
          element={
            <Suspense fallback={<Backdrop show />}>
              <Contacto />
            </Suspense>
          }
        />
        <Route
          exact
          path="/facturacion"
          element={
            <Suspense fallback={<Backdrop show />}>
              <Facturacion />
            </Suspense>
          }
        />
        <Route
          exact
          path="/historial-clinico"
          element={
            <Suspense fallback={<Backdrop show />}>
              <HistorialClinico />
            </Suspense>
          }
        />
        <Route
          exact
          path="/configCuenta"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ProfileViewProvider>
                <ConfigCuenta />
              </ProfileViewProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/mis-recetas"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ProfileViewProvider>
                <MisRecetas />
              </ProfileViewProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/mis-resultados"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ProfileViewProvider>
                <MisLaboratorios />
              </ProfileViewProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/editar-perfil"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ProfileViewProvider>
                <EditarPerfil />
              </ProfileViewProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/verificacion"
          element={
            <Suspense fallback={<Backdrop show />}>
              <Bienvenida />
            </Suspense>
          }
        />
        <Route
          exact
          path="/auth"
          element={
            <Suspense fallback={<Backdrop show />}>
              <Checkredirect />
            </Suspense>
          }
        />
        <Route
          exact
          path="/auth/registro"
          element={
            <Suspense fallback={<Backdrop show />}>
              <AccountDetail />
            </Suspense>
          }
        />
        <Route
          exact
          path="/especialidades"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SpecialitiesProvider>
                <Specialities />
              </SpecialitiesProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/lista-servicios"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SpecialitiesProvider>
                <ServicesList />
              </SpecialitiesProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/detalle-servicio"
          element={
            <Suspense fallback={<Backdrop show />}>
              <SpecialitiesProvider>
                <ServiceDetail />
              </SpecialitiesProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/article/blog/:id"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ArticleDetailProvider>
                <ArticleDetail props />
              </ArticleDetailProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/detalle-semanal/"
          element={
            <Suspense fallback={<Backdrop show />}>
              <HomeProvider>
                <WeeklyDetail props />
              </HomeProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/objetivo"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TargetSelection props />
            </Suspense>
          }
        />
        <Route
          exact
          path="/parametros/embarazo"
          element={
            <Suspense fallback={<Backdrop show />}>
              <ParametersProvider>
                <Parameters props />
              </ParametersProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/registroNacimiento"
          element={
            <Suspense fallback={<Backdrop show />}>
              <BirthFormProvider>
                <BirthForm props />
              </BirthFormProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/perdida"
          element={
            <Suspense fallback={<Backdrop show />}>
              <PregnancyLossProvider>
                <PregnancyLoss props />
              </PregnancyLossProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/mi-guia"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TimelineProvider>
                <TimelineGuide props />
              </TimelineProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/detalles-membresia"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TimelineProvider>
                <MembershipDetails props />
              </TimelineProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/plan-actual"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TimelineProvider>
                <CurrentPlan props />
              </TimelineProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/detalles-plan"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TimelineProvider>
                <PlanDetails props />
              </TimelineProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/contratacion-membresia"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TimelineProvider>
                <SingUpMembership props />
              </TimelineProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/bienvenida-membresia"
          element={
            <Suspense fallback={<Backdrop show />}>
              <TimelineProvider>
                <WelcomeMembership props />
              </TimelineProvider>
            </Suspense>
          }
        />
        <Route
          exact
          path="/contacto-consejeria"
          element={
            <Suspense fallback={<Backdrop show />}>
              <CounselingProvider>
                <Counseling props />
              </CounselingProvider>
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default routes;
