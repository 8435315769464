import React from "react";
import Grid from "@mui/material/Grid";
import classes from "./GoTo.module.css";

const GoTo = ({
  onClick,
  typeStyle,
  className,
  disabled,
  children,
  id,
  classAnalytics,
}) => {
  return (
    <button
      className={`${classAnalytics && classAnalytics} ${classes.goToButton} ${classes[typeStyle]} ${
        className ? className : null
      }`}
      id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <Grid container>{children}</Grid>
    </button>
  );
};

export default GoTo;
